import './App.scss';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { LoginView } from './views/LoginView';
import { CampaignsView } from './views/CampaignsView';
import { CampaignView } from './views/CampaignView';

export const App = () => {
    return (
        <BrowserRouter>
            <Switch>
                <Route path={'/login'} exact render={() => <LoginView />} />
                <Route path={'/campaigns'} exact render={() => <CampaignsView />} />
                <Route path={'/campaigns/:reference'} exact render={() => <CampaignView />} />
                <Route render={() => <Redirect to='/campaigns' />} />
            </Switch>
        </BrowserRouter>
    )
}
