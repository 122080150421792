import '../../styles/InvalidEmailsModal.scss';

export const InvalidEmailsModal = ({ isVisible, emailAddresses, forceUploadEvent, cancelUploadEvent }) => {
    return (
        <div id="invalid-emails-modal" style={{ display: isVisible ? 'flex' : 'none' }}>
            <div className="modal">
                <p>Las siguientes direcciones de correo no son válidas:</p>
                <div className='email-addresses'>
                    {emailAddresses?.map(email => {
                        return <div className='address'>{email}</div>;
                    })}
                </div>

                <div className='buttons'>
                    <div id='cancel-upload-btn' onClick={cancelUploadEvent}>Detener</div>
                    <div id='force-upload-btn' onClick={forceUploadEvent}>Forzar</div>
                </div>
            </div>
        </div>
    )
}