import { useEffect, useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import { baseApi } from "../apis/baseApi";
import { ProtectedViewContainer } from "../components/shared/ProtectedViewContainer"
import * as moment from 'moment';
import '../styles/CampaignsView.scss';

export const CampaignsView = () => {
    const history = useHistory();

    const [campaigns, setCampaigns] = useState([]);

    useEffect(() => {
        baseApi.get('/campaigns').then(response => {
            setCampaigns(response.data.campaigns);
        });
    }, []);

    const createCampaignBtn_Click = () => {
        baseApi.post('/campaigns').then(response => {
            const { reference } = response.data;
            history.push(`/campaigns/${reference}`);
        });
    };
    
    const logoutBtn_Click = () => {
        localStorage.removeItem('access_token');
        history.push('/login');
    };

    return (
        <ProtectedViewContainer>
            <div id="campaigns-view">
                <div className="container">
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px', alignItems: 'center' }}>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', cursor: 'pointer' }} onClick={logoutBtn_Click}>
                            <img src={require('../assets/logout.png')} width="26px" style={{ marginRight: '5px' }} />
                            Cerrar sesión
                        </div>
                    </div>
                    <div className="header">
                        <div className="title">
                            <div className="icon"></div>
                            <h1>Campañas</h1>
                        </div>
                        <div className="options">
                            <div className="create-btn" onClick={createCampaignBtn_Click}></div>
                        </div>
                    </div>
                    <div className="campaigns-list">
                        {campaigns.map(campaign => {
                            return <Link className="campaign" to={`/campaigns/${campaign.reference}`}>
                                <div className="left">
                                    <div className="subject">{campaign.emailSubject || <i>Sin asunto del correo</i>}</div>
                                    <div className="reference">{campaign.reference}</div>
                                    <div className="creation-date">Creada el {moment(campaign.creationDate).format('DD/MM/YYYY [a las] HH:mm:ss')}</div>
                                </div>
                            </Link>
                        })}
                    </div>
                </div>
            </div>
        </ProtectedViewContainer>
    )
}