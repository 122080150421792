import { useEffect, useState } from "react"
import { Redirect } from "react-router";
import { baseApi } from "../../apis/baseApi";

export const ProtectedViewContainer = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(0);

    useEffect(() => {
        baseApi.post('/auth/verify').then(response => {
            if (response.status === 200) {
                return setIsAuthenticated(1);
            }

            localStorage.removeItem('access_token');
            setIsAuthenticated(-1);
        }).catch(err => {
            localStorage.removeItem('access_token');
            setIsAuthenticated(-1);
        });
    }, []);

    // if (isAuthenticated === 1) {
    //     return children;
    // }
    
    if (isAuthenticated === -1) {
        return <Redirect to='/login' />
    }

    // return (
    //     <div>Loading...</div>
    // )

    return children;
}