import '../../styles/PreviewTemplateModal.scss';

export const PreviewTemplateModal = ({ htmlTemplate, emailBody, isVisible, closeBtn_Click }) => {
    const html = htmlTemplate?.length > 0
        ? htmlTemplate.replace('__BODY__', emailBody.split('\n').join('<br />'))
        : emailBody.split('\n').join('<br />');

    return (
        <div id='preview-template-modal' style={{ display: isVisible ? 'flex' : 'none' }}>
            <div className='modal'>
                <div onClick={closeBtn_Click} style={{ textAlign: 'right', marginBottom: '10px' }}>[X]</div>
                <iframe srcDoc={html} frameborder="0"></iframe>
            </div>
        </div>
    )
}