import axios from 'axios';

export const baseApi = axios.create({
    // baseURL: 'http://localhost:8080',
    baseURL: 'https://email-sender-service-gdcedey3qq-uc.a.run.app',
});

baseApi.interceptors.request.use(async (config) => {
    const token = localStorage.getItem('access_token');
    config.headers['Authorization'] = `Bearer ${token}`;
    return config;
}, (err) => {
    return Promise.reject(err);
});