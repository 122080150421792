import { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { baseApi } from '../apis/baseApi';
import '../styles/LoginView.scss';


export const LoginView = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isAuthenticated, setIsAuthenticated] = useState(0);

    const loginBtn_Click = () => {
        baseApi.post('/auth/login', { username, password }).then(response => {
            localStorage.setItem('access_token', response.data.access_token);
            setIsAuthenticated(1);
        });
    }

    useEffect(() => {
        const accessToken = localStorage.getItem('access_token');
        setIsAuthenticated(accessToken ? 1 : -1);
    }, []);

    if (isAuthenticated === 1) {
        return <Redirect to='/campaigns' />
    }

    return (
        <div id="login-view">
            <div className='container'>
                <div className='icon'></div>
                <input type="text" placeholder='Usuario' value={username} onChange={e => setUsername(e.currentTarget.value)} />
                <input type="password" placeholder='Contraseña' value={password} onChange={e => setPassword(e.currentTarget.value)} />
                <button onClick={loginBtn_Click}>Iniciar sesión</button>
            </div>
        </div>
    )
}